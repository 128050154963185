import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "@emotion/styled"

import LayoutLight from "../components/LayoutLight"

const Container = styled.div`
    padding: 15vh 12vw 15vw 12vw;

    & > .liste {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-gap: 50px;

        @media screen and (max-width: 768px) {
            grid-template-columns: 100%;
        }

        & > .article {
            color: primary;
            background-color: secondary;

            &:after {
                content: '';
                display: block;
                width: 100%;
                border-top: 2px solid;
                margin: 10px auto 16px;
              }
        }
    }

    h3 {
        font-size: 1rem;
        color: grey;
    }
`

export default function Blog() {
    const { allGraphCmsPost } = useStaticQuery(graphql`
        {
            allGraphCmsPost(sort: {fields: date, order: DESC}) {
                nodes {
                    id
                    title
                    slug
                    date
                    author { name }
                    cover { url }

                }
            }
        }
    `)

    console.log(allGraphCmsPost)


    return (
        <LayoutLight>
            <Container>
                <h1>Articles</h1>
                <div className="liste">
                    {allGraphCmsPost.nodes.map(post =>(
                        <Link to={`/blog/${post.slug}`} key={post.id} className="article">
                            <h2>{post.title}</h2>
                            <h3>{post.author.name}, {post.date}</h3>
                            { post.cover && post.cover.url &&
                                <img
                                    style={{ maxWidth: "100%", height: "inherit" }}
                                    src={post.cover.url}
                                />
                            }
                        </Link>
                    ))}
                </div>
            </Container>
        </LayoutLight>
    )
}